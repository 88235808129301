import Module from './Module';

export default class ModuleBanner extends Module {
	constructor(el) {
		super(el);
		this.view = document.querySelector('#site');
		this.mainHeader = document.querySelector('header.main-header');
		this.nav = document.querySelectorAll('header.main-header nav');
		this.closeBtn = this.dom.el.querySelector('.banner__button');
		this.dataView = document.querySelector('[data-view]');
		this.mediaQuery = window.matchMedia('(max-width: 990px)');
	}

	init() {
		if (this.checkCookies()) {
			this.hideBanner();
			this.managePaddingTop();
		} else {
			this.setCSSCustomProps();
			this.bindEvents();
			this.setResponsiveClasses();
			this.setResponsiveClasses('banner__item');
			this.setResponsiveClasses('cta');
			this.setCloseBannerButton();
			this.showBanner();
			this.managePaddingTop();
		}
	}

	checkCookies() {
		const cookieName =
			this.dom.el.dataset.contentSid || this.dom.el.dataset.contentFid;
		return document.cookie
			.split(';')
			.some((item) => item.trim().startsWith(`${cookieName}=`));
	}

	setCookies() {
		const sessionCookieName = this.dom.el.dataset.contentSid;
		if (sessionCookieName) {
			document.cookie = `${sessionCookieName}=true; path=/`;
		}
		const foreverCookieName = this.dom.el.dataset.contentFid;
		if (foreverCookieName) {
			let cookieDate = new Date();
			cookieDate.setMonth(cookieDate.getMonth() + 1);
			document.cookie = `${foreverCookieName}=true; expires=${cookieDate.toGMTString()}; path=/`;
		}
	}

	showBanner() {
		this.dom.el.style.display = 'flex';
		this.dom.el.setAttribute('aria-hidden', 'false');
	}

	hideBanner() {
		this.dom.el.style.display = 'none';
		this.dom.el.setAttribute('aria-hidden', 'true');
	}

	setCSSCustomProps() {
		const banners = this.dom.el.querySelectorAll('.banner__item');
		if (banners) {
			[].forEach.call(banners, (banner) => {
				const desktopBgColor = banner.dataset.desktopBackgroundcolor;
				const mobileBgColor = banner.dataset.mobileBackgroundcolor;
				const desktopTxtColor = banner.dataset.desktopTextcolor;
				const mobileTxtColor = banner.dataset.mobileTextcolor;

				if (desktopBgColor && mobileBgColor) {
					banner.style.setProperty('--bg-desktop-color', `${desktopBgColor}`);
					banner.style.setProperty('--bg-mobile-color', `${mobileBgColor}`);
				}
				if (desktopTxtColor && mobileTxtColor) {
					banner.style.setProperty('--txt-desktop-color', `${desktopTxtColor}`);
					banner.style.setProperty('--txt-mobile-color', `${mobileTxtColor}`);
				}
				this.handleBannerIcon(banner);
				this.handleBannerImage(banner);
				this.initCountdown(banner);
				this.triggerTracking(banner);
			});
		}
	}

	managePaddingTop() {
		// this is to handle the difference betweeen the legacy nav and universal nav
		// Legacy Nav was 48px of height / Universal Nav is 60 px of height
		// Once the legacy nav is not on the website anymmore and that [data-view] doesnt have 48px padding top anymore
		// then you can remove that extraPadding
		// Similar functions are in
		// C:\Git\CDS1024\Components\src\Foundation\Theming\code\src\Components\universal-nav\assets\scripts\modules\LegacySupport.js
		// C:\Git\CDS1024\Components\src\Foundation\Theming\code\src\Components\banner\assets\scripts\modules\ModuleBanner.js
		// C:\Git\CDS1024\CdsDotCom\src\Foundation\Frontend\code\front-end\component\core\src\js\modules\ModulePromoBanner.js
		if (this.mainHeader && this.nav) {
			let extraPadding = 0;
			if (
				this.dataView &&
				getComputedStyle(this.dataView).paddingTop === '48px'
			) {
				extraPadding += 12;
			}
			const isLegacyNav = this.nav.length > 1;

			let navClientHeight = 0;
			if (this.nav.length > 0) {
				navClientHeight =
					this.nav[0].clientHeight +
					(this.nav[1] ? this.nav[1].clientHeight : 0);
			}

			const newPaddingTop =
				this.mainHeader.clientHeight -
				navClientHeight +
				(isLegacyNav ? 0 : extraPadding);

			this.view.style.paddingTop = `${newPaddingTop}px`;
		}
	}

	bindEvents() {
		const mediaQuery = window.matchMedia('(max-width: 990px)');

		if (this.closeBtn) {
			this.closeBtn.addEventListener('click', () => {
				this.setCookies();
				this.hideBanner();
			});
		}
		mediaQuery.addEventListener('change', this.managePaddingTop);
	}

	setResponsiveClasses(target) {
		const mediaQuery = window.matchMedia('(max-width: 990px)');

		let elements = [this.dom.el];
		if (target) {
			elements = this.dom.el.querySelectorAll(`.${target}`);
		}
		if (elements) {
			[].forEach.call(elements, (elem) => {
				mediaQuery.addEventListener('change', (e) => {
					this.handleResponsiveClasses(e, elem);
				});
				this.handleResponsiveClasses(mediaQuery, elem);
			});
		}
	}

	handleResponsiveClasses(e, elem) {
		const dataDesktop = elem.dataset.desktopClasses;
		const dataMobile = elem.dataset.mobileClasses;

		if (dataDesktop && dataMobile) {
			const elemDesktopClass = dataDesktop.split(' ');
			const elemMobileClass = dataMobile.split(' ');
			if (e.matches) {
				elemDesktopClass.forEach((item) => {
					elem.classList.remove(`${item}`);
				});
				elemMobileClass.forEach((item) => {
					elem.classList.add(`${item}`);
				});
			} else {
				elemMobileClass.forEach((item) => {
					elem.classList.remove(`${item}`);
				});
				elemDesktopClass.forEach((item) => {
					elem.classList.add(`${item}`);
				});
			}
		}
	}

	setCloseBannerButton() {
		const mediaQuery = window.matchMedia('(max-width: 990px)');
		if (this.closeBtn && this.closeBtn.dataset.mobileBgimage) {
			mediaQuery.addEventListener('change', (e) => {
				this.handleCloseBannerButton(e);
			});
			this.handleCloseBannerButton(mediaQuery);
		}
	}

	handleCloseBannerButton(e) {
		const imageMobile = this.closeBtn.dataset.mobileBgimage;
		const imageDesktop = this.closeBtn.dataset.desktopBgimage;
		if (e.matches) {
			this.closeBtn.style.backgroundImage = `url('${imageMobile}')`;
		} else {
			this.closeBtn.style.backgroundImage = `url('${imageDesktop}')`;
		}
	}

	handleBannerImage(banner) {
		this.setBannerMedia(banner, '.banner__image');
	}

	handleBannerIcon(banner) {
		this.setBannerMedia(banner, '.banner__icon');
	}

	setBannerMedia(banner, selector) {
		const mediaQuery = window.matchMedia('(max-width: 990px)');
		const media = banner.querySelector(selector);
		if (media && media.dataset.desktopMediaUrl) {
			mediaQuery.addEventListener('change', (e) => {
				this.handleBannerMedia(e, media);
			});
			this.handleBannerMedia(mediaQuery, media);
		}
	}

	handleBannerMedia(e, media) {
		const mobileMediaUrl = media.dataset.mobileMediaUrl;
		const mobileMediaAlt = media.dataset.mobileMediaAlt;
		const desktopMediaUrl = media.dataset.desktopMediaUrl;
		const desktopMediaAlt = media.dataset.desktopMediaAlt;

		if (e.matches) {
			media.setAttribute('src', mobileMediaUrl);
			media.setAttribute('alt', mobileMediaAlt);
		} else {
			media.setAttribute('src', desktopMediaUrl);
			media.setAttribute('alt', desktopMediaAlt);
		}
	}

	triggerTracking(banner) {
		if (banner.dataset.ecommerceGa4) {
			const events = this.cleanJSON(banner.dataset.ecommerceGa4);
			this.pushDatalayer(events);
		}
	}

	pushDatalayer(datalayerEvent) {
		if (!datalayerEvent) return;
		if (!window.dataLayer) window.dataLayer = [];
		if (Array.isArray(datalayerEvent)) {
			for (let i = 0; i < datalayerEvent.length; i += 1) {
				window.dataLayer.push(datalayerEvent[i]);
			}
		} else {
			window.dataLayer.push(datalayerEvent);
		}
	}

	cleanJSON(strJSON) {
		if (!strJSON) return;
		if (typeof strJSON !== 'string') return strJSON;

		let s = strJSON
			.replace(/\\n/g, '\\n')
			.replace(/\\'/g, "\\'")
			.replace(/\\"/g, '\\"')
			.replace(/\\&/g, '\\&')
			.replace(/\\r/g, '\\r')
			.replace(/\\t/g, '\\t')
			.replace(/\\b/g, '\\b')
			.replace(/\\f/g, '\\f');

		// remove non-printable and other non-valid JSON chars
		s = s.replace(/[\u0000-\u0019]+/g, '');
		return JSON.parse(s);
	}

	initCountdown(banner) {
		var countdown = banner.querySelector('.banner__countdown');

		if (countdown) {
			let currentTime = parseFloat(countdown.dataset.currentTime);
			let eventTime = parseFloat(countdown.dataset.endTime);
			let $sec = countdown.querySelector('.countdown__seconds');
			let $min = countdown.querySelector('.countdown__minutes');
			let $hours = countdown.querySelector('.countdown__hours');
			let $days = countdown.querySelector('.countdown__days');

			let updateTimer = () => {
				currentTime += 1000;
				let diff = eventTime - currentTime;

				if (diff <= 0) {
					clearInterval(interval);
					$sec.textContent = '00';
					$min.textContent = '00';
					$hours.textContent = '00';
					$days.textContent = '0';
				} else {
					if ($sec) {
						let seconds = Math.floor((diff / 1000) % 60);
						$sec.textContent = `0${seconds}`.slice(-2);
					}

					if ($min) {
						let minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
						$min.textContent = minutes;
					}

					if ($hours) {
						let hours = Math.floor(
							(diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
						);
						$hours.textContent = hours;
					}

					if ($days) {
						let days = Math.floor(diff / (1000 * 60 * 60 * 24));
						$days.textContent = days;
					}
				}

				countdown.classList.add('banner__countdown--isLoaded');
			};

			let interval = setInterval(updateTimer, 1000);
		}
	}
}
